<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="8" class="pa-0">
      <card-initializing
        v-if="isInitializing"
        :text="`Loading Agent ${agent_id}`"
      />
      <card-not-found
        v-else-if="!agentExists"
        :back-button-route="AGENTS_ROUTE"
        :text="`Agent Not Found (${agent_id})`"
        back-button-tooltip="Agent List"
      />
      <v-card
        v-else
        class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
        max-width="100%"
        outlined
      >
        <card-toolbar
          :back-button-route="AGENTS_ROUTE"
          :icon="AGENT_ICON"
          :title="`${AGENT_VUE} ${agent_id}`"
          @collapse-all="panel = []"
          @expand-all="panel = panelsExpanded"
          @refresh-data="refreshAgent()"
          back-button-tooltip="Agent List"
          button-mode
          include-back
          include-collapse
          include-expand
          include-help
          include-refresh
        />
        <help-text :page="AGENT_VUE" />

        <v-expansion-panels
          v-model="panel"
          class="mt-4 pb-4 px-0"
          multiple
          popout
        >
          <crud-expansion-panel
            :disable-save="isAgentSaveDisabled"
            :help-page="AGENT_VUE_AGENT_EXPAND"
            :loading="loading[AGENT]"
            :readonly="!agent.isEditing(AGENT)"
            :saving="saving[AGENT]"
            @cancel-edit="agent.cancelEdit(AGENT)"
            @enable-edit="agent.enableEdit(AGENT)"
            @save-data="updateAgentData(AGENT)"
            type="Agent"
          >
            <template v-slot:title>
              <expansion-panel-title-with-text
                :display="isAgentPanelTextDisplayed"
                title="Agent"
                :text="agent.getSummaryText(AGENT)"
              />
            </template>
            <template v-slot:content>
              <v-fade-transition>
                <v-form
                  v-if="!loading[AGENT]"
                  v-model="validated[AGENT]"
                  @submit.prevent
                >
                  <v-row v-for="(fieldGroup, i) in agentFieldGroups" :key="i">
                    <v-col
                      v-for="(field, j) in fieldGroup" :key="j"
                      dense
                      cols="12" :sm="field.smColumns" :md="field.mdColumns"
                    >
                      <v-text-field
                        v-if="field.fieldType === TEXT"
                        v-model="agent[DATA][AGENT][field.property]"
                        :clearable="agent.isEditing(AGENT)"
                        :counter="field.counter"
                        :label="field.label"
                        :readonly="!agent.isEditing(AGENT)"
                        :rules="field.rules"
                        dense
                      />
                      <v-select
                        v-else-if="field.fieldType === SELECT"
                        v-model="agent[DATA][AGENT][field.property]"
                        :item-text="(field.textProperty !== null) ? field.textProperty : null"
                        :item-value="(field.textProperty !== null) ? field.textProperty : null"
                        :items="field.items"
                        :label="field.label"
                        :readonly="!agent.isEditing(AGENT)"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-fade-transition>
            </template>
          </crud-expansion-panel>
          
          <!-- Agent profile. -->
          <crud-expansion-panel
            :deleting="deleting[AGENT_PROFILE]"
            :disable-add="isAddDisabled(AGENT_PROFILE)"
            :disable-save="isAgentAliasSaveDisabled"
            :help-page="AGENT_VUE_AGENT_PROFILE_EXPAND"
            :loading="loading[AGENT_PROFILE]"
            :readonly="!agent.isEditing(AGENT_PROFILE)"
            :saving="saving[AGENT_PROFILE]"
            @add-data="openCrudDialog(AGENT_PROFILE)"
            @cancel-edit="agent.cancelEdit(AGENT_PROFILE)"
            @create-data="createData(AGENT_PROFILE)"
            @delete-data="openConfirmationDialog(DELETE, AGENT_PROFILE, agent[DATA][AGENT_PROFILE][selectedProfileIndex])"
            @enable-edit="agent.enableEdit(AGENT_PROFILE)"
            @save-data="updateAgentData(AGENT_PROFILE)"
            include-add
            include-delete
            type="Profile"
          >
            <template v-slot:title>
              <expansion-panel-title-with-text
                :display="isAgentProfilePanelTextDisplayed"
                :text="agent.getSummaryText(AGENT_PROFILE)"
                title="Agent Profiles"
              />
            </template>
            <template v-slot:content>
              <v-form
                v-if="!isEmpty(agent[DATA][AGENT_PROFILE])"
                v-model="validated[AGENT_PROFILE]"
                @submit.prevent
              >
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      v-model="selectedProfile"
                      :disabled="agent.isEditing(AGENT_PROFILE)"
                      :items="agent[DATA][AGENT_PROFILE]"
                      :label="agentProfileLabel"
                      :prepend-icon="AGENT_BOX_ICON"
                      class="mt-0 pt-0"
                      item-text="profile_id"
                    />
                  </v-col>
                </v-row>
                <v-row v-for="(fieldGroup, i) in agent.getFieldGroups(AGENT_PROFILE)" :key="i">
                  <v-col
                    v-for="(field, j) in fieldGroup" :key="j"
                    dense
                    cols="12" :sm="field.smColumns" :md="field.mdColumns"
                  > 
                    <v-text-field
                      v-if="field.fieldType === TEXT"
                      v-model="agent[DATA][AGENT_PROFILE][selectedProfileIndex][field.property]"
                      :clearable="agent.isEditing(AGENT_PROFILE)"
                      :counter="field.counter"
                      :label="field.label"
                      :readonly="!agent.isEditing(AGENT_PROFILE)"
                      :rules="field.rules"
                      dense
                    />
                    <v-select
                      v-else-if="field.fieldType === SELECT"
                      v-model="agent[DATA][AGENT_PROFILE][selectedProfileIndex][field.property]"
                      :item-text="(field.textProperty !== null) ? field.textProperty : null"
                      :item-value="(field.textProperty !== null) ? field.textProperty : null"
                      :items="field.items"
                      :label="field.label"
                      :readonly="!agent.isEditing(AGENT_PROFILE)"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </crud-expansion-panel>

          <!-- Agent aliases. -->
          <crud-expansion-panel
            :deleting="deleting[AGENT_ALIAS]"
            :disable-add="isAddDisabled(AGENT_ALIAS)"
            :disable-delete="isDeleteDisabled(AGENT_ALIAS)"
            :disable-save="isAgentAliasSaveDisabled"
            :help-page="AGENT_VUE_AGENT_ALIAS_EXPAND"
            :loading="loading[AGENT_ALIAS]"
            :readonly="!agent.isEditing(AGENT_ALIAS)"
            :saving="saving[AGENT_ALIAS]"
            @cancel-edit="agent.cancelEdit(AGENT_ALIAS)"
            @enable-edit="agent.enableEdit(AGENT_ALIAS)"
            @save-data="updateAgentData(AGENT_ALIAS)"
            type="Alias"
          >
            <template v-slot:title>
              <expansion-panel-title-with-text
                :display="isAgentAliasPanelTextDisplayed"
                :text="agent.getSummaryText(AGENT_ALIAS)"
                title="Aliases"
              />
            </template>
            <template v-slot:content>
              <v-form
                v-if="!isEmpty(agent[DATA][AGENT_ALIAS])"
                v-model="validated[AGENT_ALIAS]"
                @submit.prevent
              >
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      v-model="selectedProfile"
                      :disabled="agent.isEditing(AGENT_ALIAS) || agent.isEditing(AGENT_PROFILE)"
                      :items="agent[DATA][AGENT_PROFILE]"
                      :label="agentProfileLabel"
                      :prepend-icon="AGENT_BOX_ICON"
                      class="my-0 py-0"
                      item-text="profile_id"
                    />
                  </v-col>
                </v-row>
                <v-row v-for="(fieldGroup, i) in agent.getFieldGroups(AGENT_ALIAS)" :key="i">
                  <v-col
                    v-for="(field, j) in fieldGroup" :key="j"
                    dense
                    cols="12" :sm="field.smColumns" :md="field.mdColumns"
                  > 
                    <v-text-field
                      v-if="field.fieldType === TEXT"
                      v-model="agent[DATA][AGENT_ALIAS][selectedProfileIndex][field.property]"
                      :clearable="agent.isEditing(AGENT_ALIAS)"
                      :counter="field.counter"
                      :label="field.label"
                      :readonly="!agent.isEditing(AGENT_ALIAS)"
                      :rules="field.rules"
                      dense
                    />
                    <v-select
                      v-else-if="field.fieldType === SELECT"
                      v-model="agent[DATA][AGENT_ALIAS][selectedProfileIndex][field.property]"
                      :item-text="(field.textProperty !== null) ? field.textProperty : null"
                      :item-value="(field.textProperty !== null) ? field.textProperty : null"
                      :items="field.items"
                      :label="field.label"
                      :readonly="!agent.isEditing(AGENT_ALIAS)"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </crud-expansion-panel>

          <crud-expansion-panel
            :disable-save="isAgentBusinessAddressSaveDisabled"
            :help-page="AGENT_VUE_BUSINESS_ADDRESS_EXPAND"
            :loading="loading[AGENT_BUSINESS_ADDRESS]"
            :readonly="!agent.isEditing(AGENT_BUSINESS_ADDRESS)"
            :saving="saving[AGENT_BUSINESS_ADDRESS]"
            @cancel-edit="agent.cancelEdit(AGENT_BUSINESS_ADDRESS)"
            @enable-edit="agent.enableEdit(AGENT_BUSINESS_ADDRESS)"
            @save-data="updateAgentData(AGENT_BUSINESS_ADDRESS)"
            type="Business Address"
          >
            <template v-slot:title>
              <expansion-panel-title-with-text
                :display="isAgentBusinessAddressPanelTextDisplayed"
                :text="agent.getSummaryText(AGENT_BUSINESS_ADDRESS)"
                title="Business Address"
              />
            </template>
            <template v-slot:content>
              <v-fade-transition>
                <v-form
                  v-if="!isEmpty(agent[DATA][AGENT_BUSINESS_ADDRESS])"
                  v-model="validated[AGENT_BUSINESS_ADDRESS]"
                  @submit.prevent
                >
                  <v-row v-for="(fieldGroup, i) in agent.getFieldGroups(AGENT_BUSINESS_ADDRESS)" :key="i">
                    <v-col
                      v-for="(field, j) in fieldGroup" :key="j"
                      dense
                      cols="12" :sm="field.smColumns" :md="field.mdColumns"
                    >
                      <v-text-field
                        v-if="field.fieldType === TEXT"
                        v-model="agent[DATA][AGENT_BUSINESS_ADDRESS][field.property]"
                        :clearable="agent.isEditing(AGENT_BUSINESS_ADDRESS)"
                        :counter="field.counter"
                        :label="field.label"
                        :readonly="!agent.isEditing(AGENT_BUSINESS_ADDRESS)"
                        :rules="field.rules"
                        dense
                      />
                      <v-select
                        v-else-if="field.fieldType === SELECT"
                        v-model="agent[DATA][AGENT_BUSINESS_ADDRESS][field.property]"
                        :item-text="(field.textProperty !== null) ? field.textProperty : null"
                        :item-value="(field.textProperty !== null) ? field.textProperty : null"
                        :items="field.items"
                        :label="field.label"
                        :readonly="!agent.isEditing(AGENT_BUSINESS_ADDRESS)"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-fade-transition>
            </template>
          </crud-expansion-panel>

          <crud-expansion-panel
            :deleting="deleting[AGENT_SOCIAL_MEDIA]"
            :disable-add="isAddDisabled(AGENT_SOCIAL_MEDIA)"
            :disable-save="isAgentSocialMediaSaveDisabled"
            :help-page="AGENT_VUE_SOCIAL_MEDIA_EXPAND"
            :is-empty="isEmpty(agent[DATA][AGENT_SOCIAL_MEDIA])"
            :loading="loading[AGENT_SOCIAL_MEDIA]"
            :readonly="!agent.isEditing(AGENT_SOCIAL_MEDIA)"
            :saving="saving[AGENT_SOCIAL_MEDIA]"
            @add-data="openCrudDialog(AGENT_SOCIAL_MEDIA)"
            @cancel-edit="agent.cancelEdit(AGENT_SOCIAL_MEDIA)"
            @enable-edit="agent.enableEdit(AGENT_SOCIAL_MEDIA)"
            @save-data="updateAgentData(AGENT_SOCIAL_MEDIA)"
            include-add
            type="Social Media"
          >
            <template v-slot:title>
              <expansion-panel-title-with-icons
                :display="isAgentSocialMediaPanelTextDisplayed"
                :items="agent[DATA][AGENT_SOCIAL_MEDIA]"
                property="social_media"
                title="Social Media"
              />
            </template>
            <template v-slot:content>
              <v-fade-transition>
                <v-form
                  v-if="!isEmpty(agent[DATA][AGENT_SOCIAL_MEDIA])"
                  v-model="validated[AGENT_SOCIAL_MEDIA]"
                  @submit.prevent
                >
                  <v-row v-for="(item, i) in agent.getSocialMediaFields()" :key="i">
                    <v-col
                      class="d-flex align-top"
                      cols="12"
                    >
                      <v-text-field
                        v-model="agent[DATA][AGENT_SOCIAL_MEDIA][i].url"
                        :counter="item.counter"
                        :label="item.label"
                        :prepend-icon="item.iconPrepend"
                        :readonly="!agent.isEditing(AGENT_SOCIAL_MEDIA)"
                        :rules="item.rules"
                        dense
                      />
                      <tooltip-fab-icon-button
                        v-show="agent.isEditing(AGENT_SOCIAL_MEDIA)"
                        :disabled="!agent.isEditing(AGENT_SOCIAL_MEDIA)"
                        :icon-name="DELETE_ICON"
                        :text="`Delete ${item.social_media}`"
                        @click="openConfirmationDialog(DELETE, AGENT_SOCIAL_MEDIA, agent[DATA][AGENT_SOCIAL_MEDIA][i])"
                        bottom
                        color="error"
                        icon
                        small
                      />
                    </v-col>
                  </v-row>
                </v-form>
                <v-alert
                  v-else
                  :icon="INFO_ICON"
                  :type="INFO"
                  outlined
                  text
                >
                  This agent does not have any social media.
                </v-alert>
              </v-fade-transition>
            </template>
          </crud-expansion-panel>

          <crud-expansion-panel
            :deleting="deleting[AGENT_TEAM]"
            :disable-save="isAgentTeamAssnSaveDisabled"
            :help-page="AGENT_VUE_TEAM_ASSN_EXPAND"
            :is-empty="isEmpty(agent[DATA][AGENT_TEAM])"
            :loading="loading[AGENT_TEAM]"
            :readonly="!agent.isEditing(AGENT_TEAM)"
            :saving="saving[AGENT_TEAM]"
            @cancel-edit="agent.cancelEdit(AGENT_TEAM)"
            @enable-edit="agent.enableEdit(AGENT_TEAM)"
            @save-data="updateAgentData(AGENT_TEAM)"
            type="Team"
          >
            <template v-slot:title>
              <expansion-panel-title-with-text
                :display="isAgentTeamAssnPanelTextDisplayed"
                :text="agent.getSummaryText(AGENT_TEAM)"
                title="Team Association"
              />
            </template>
            <template v-slot:content>
              <v-fade-transition>
                <v-form
                  v-model="validated[AGENT_TEAM]"
                  @submit.prevent
                >
                  <v-row v-for="(fieldGroup, i) in agent.getFieldGroups(AGENT_TEAM)" :key="i">
                    <v-col
                      v-for="(field, j) in fieldGroup" :key="j"
                      cols="12" :sm="field.smColumns" :md="field.mdColumns"
                      class="d-flex align-top"
                      dense
                    >
                      <v-select
                        v-if="field.fieldType === SELECT"
                        v-model="agent[DATA][AGENT_TEAM][field.property]"
                        :item-text="(field.textProperty !== null) ? field.textProperty : null"
                        :item-value="(field.textProperty !== null) ? field.textProperty : null"
                        :items="field.items"
                        :label="field.label"
                        :readonly="!agent.isEditing(AGENT_TEAM)"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-fade-transition>
            </template>
          </crud-expansion-panel>

          <crud-expansion-panel
            disable-toolbar
            :loading="loading[AGENT_CLIENT_ASSN]"
            type="Agent-Client Association"
          >
            <template v-slot:title>
              <expansion-panel-title-with-text
                :display="isClientAssociationPanelTextDisplayed"
                title="Associated Clients"
                :text="clientAssociationSummaryText"
              />
            </template>
            <template v-slot:content>
              <card-group-associations
                :association-data="selectedProfileClientAssociations"
                :association-target-data="filteredClientAssociations"
                :create-function="createAgentData"
                :delete-function="deleteAgentData"
                :deleting="deleting[AGENT_CLIENT_ASSN]"
                disable-action
                :saving="saving[AGENT_CLIENT_ASSN]"
                @search-input="clientAssociationSearch = $event"
                :type="AGENT_CLIENT_ASSN"
              >
                <template slot="before-content">
                  <v-col cols="12">
                    <v-row class="mt-2 py-0">
                      <v-col cols="12" sm="6" md="3" class="mb-0 pb-0">
                        <v-select
                          v-model="selectedProfile"
                          :disabled="agent.isEditing(AGENT_PROFILE)"
                          :items="agent[DATA][AGENT_PROFILE]"
                          :label="agentProfileLabel"
                          :prepend-icon="AGENT_BOX_ICON"
                          class="my-0 py-0"
                          item-text="profile_id"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </card-group-associations>
            </template>
          </crud-expansion-panel>

          <crud-expansion-panel
            disable-toolbar
            :loading="loading[AGENT_MORTGAGE_DEAL_ASSN]"
            type="Agent-Mortgage Deal Association"
          >
            <template v-slot:title>
              <expansion-panel-title-with-text
                :display="isMortgageDealAssociationPanelTextDisplayed"
                title="Associated Mortgage Deals"
                :text="mortgageDealAssociationSummaryText"
              />
            </template>
            <template v-slot:content>
              <card-group-associations
                :association-data="selectedProfileMortgageDealAssociations"
                :association-target-data="filteredMortgageDealAssociations"
                :create-function="createAgentData"
                :delete-function="deleteAgentData"
                :deleting="deleting[AGENT_MORTGAGE_DEAL_ASSN]"
                disable-action
                :saving="saving[AGENT_MORTGAGE_DEAL_ASSN]"
                @search-input="mortgageDealAssociationSearch = $event"
                :type="AGENT_MORTGAGE_DEAL_ASSN"
              >
                <template slot="before-content">
                  <v-col cols="12">
                    <v-row class="mt-2 py-0">
                      <v-col cols="12" sm="6" md="3" class="mb-0 pb-0">
                        <v-select
                          v-model="selectedProfile"
                          :disabled="agent.isEditing(AGENT_PROFILE)"
                          :items="agent[DATA][AGENT_PROFILE]"
                          :label="agentProfileLabel"
                          :prepend-icon="AGENT_BOX_ICON"
                          class="my-0 py-0"
                          item-text="profile_id"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </card-group-associations>
            </template>
          </crud-expansion-panel>
        </v-expansion-panels>

        <dialog-confirmation
          :dialog.sync="dialog[CONFIRMATION].show"
          @confirmed="deleteAgentData(dialog[CONFIRMATION].config.type, dialog[CONFIRMATION].payload)"
          :config="dialog[CONFIRMATION].config"
          :payload="dialog[CONFIRMATION].payload"
        />

        <dialog-crud
          :dialog.sync="dialog[CRUD].show"
          @save-data="createAgentData(dialog[CRUD].config.type, dialog[CRUD].payload)"
          :config="dialog[CRUD].config"
          :payload="dialog[CRUD].payload"
          :saving="!!saving[dialog[CRUD].config.type]"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep, differenceWith, filter, forEach, isEmpty, isEqual, isNil, orderBy, range, startCase, } from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { ACTIONS, EXPANDS, ICONS, ROUTES, VALID_TABLES, DIALOGS, VUES, COLOR, DATA, SAVED_DATA, MESSAGE_TYPE, } from '../constants'
import { now } from '../services/utility.service'
import { TYPE as AGENT_TYPE, isTypeIterable, PROPERTY } from '../models/dto/Agent'
import { TYPE as MORTGAGE_DEAL_TYPE } from '../models/dto/MortgageDeal'
import { TYPE as CLIENT_TYPE } from '../models/dto/Client'
import { FIELD_TYPES } from '../models/field_configs/FieldConfig'

export default {
  components: {
    CardGroupAssociations: () => import('../components/layout/CardGroupAssociations.vue'),
    CardInitializing: () => import('../components/layout/cards/CardInitializing.vue'),
    CardNotFound: () => import('../components/layout/cards/CardNotFound.vue'),
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    CrudExpansionPanel: () => import('../components/layout/CrudExpansionPanel.vue'),
    DialogConfirmation: () => import('../components/layout/dialog/DialogConfirmation.vue'),
    DialogCrud: () => import('../components/layout/dialog/DialogCrud.vue'),
    ExpansionPanelTitleWithIcons: () => import('../components/layout/ExpansionPanelTitleWithIcons.vue'),
    ExpansionPanelTitleWithText: () => import('../components/layout/ExpansionPanelTitleWithText.vue'),
    HelpText: () => import('../components/layout/HelpText.vue'),
    RowSubheader: () => import('../components/layout/RowSubheader.vue'),
    TooltipFabIconButton: () => import('../components/input/TooltipFabIconButton.vue'),
  },
  data: () => ({
    agent_id: null,    // Agent ID of this agent.
    agent: {},         // The agent.
    agentExists: true, // Flag that indicates if this agent exists.

    isInitializing: true, // Has this component page been initialized?

    clientAssociationSearch: '',       // Search field for the agent-client associations.
    mortgageDealAssociationSearch: '', // Search field for the agent-mortgage-deal associations.

    panelCount: 0,      // Number of expansion panels.
    panel: [],          // Expansion panel state (active panels).
    panelsExpanded: [], // Array for "all panels active".

    selectedProfile: null, // Currently selected agent profile.

    // Dialog window state.
    dialog: {
      [DIALOGS.CRUD]: { show: false, config: {}, payload: {}, },
      [DIALOGS.CONFIRMATION]: { show: false, config: {}, payload: {}, }
    },

    // Lists of data used by agent data-types.
    lists: { [VALID_TABLES.AGENT_PROFILE]: [], [VALID_TABLES.AGENT_TEAM]: [], [VALID_TABLES.PROVINCE]: [], [VALID_TABLES.SOCIAL_MEDIA]: [], [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL]: [], [CLIENT_TYPE.CLIENT]: [], },

    // Stores whether an agent data-type is currently "deleting" data.
    deleting: { [AGENT_TYPE.AGENT]: false, [AGENT_TYPE.AGENT_ALIAS]: false, [AGENT_TYPE.AGENT_BUSINESS_ADDRESS]: false, [AGENT_TYPE.AGENT_PROFILE]: false, [AGENT_TYPE.AGENT_SOCIAL_MEDIA]: false, [AGENT_TYPE.AGENT_CLIENT_ASSN]: false, [AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN]: false },

    // Stores whether an agent data-type is currently "loading" data.
    loading: { [AGENT_TYPE.AGENT]: false, [AGENT_TYPE.AGENT_ALIAS]: false, [AGENT_TYPE.AGENT_BUSINESS_ADDRESS]: false, [AGENT_TYPE.AGENT_PROFILE]: false, [AGENT_TYPE.AGENT_SOCIAL_MEDIA]: false, [AGENT_TYPE.AGENT_CLIENT_ASSN]: false, [AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN]: false },

    // Stores whether an agent data-type is currently "saving" data.
    saving: { [AGENT_TYPE.AGENT]: false, [AGENT_TYPE.AGENT_ALIAS]: false, [AGENT_TYPE.AGENT_BUSINESS_ADDRESS]: false, [AGENT_TYPE.AGENT_PROFILE]: false, [AGENT_TYPE.AGENT_SOCIAL_MEDIA]: false, [AGENT_TYPE.AGENT_CLIENT_ASSN]: false, [AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN]: false },

    // Stores whether the fields for an agent data-type are currently valid.
    validated: { [AGENT_TYPE.AGENT]: false, [AGENT_TYPE.AGENT_ALIAS]: false, [AGENT_TYPE.AGENT_BUSINESS_ADDRESS]: false, [AGENT_TYPE.AGENT_PROFILE]: false, [AGENT_TYPE.AGENT_SOCIAL_MEDIA]: false, [AGENT_TYPE.AGENT_CLIENT_ASSN]: false, [AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN]: false },
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),

    filteredClientAssociations () {
      const filteredBySearch = orderBy(this.lists[CLIENT_TYPE.CLIENT].filter(item => {
        return item.client_id.toString().includes(this.clientAssociationSearch.toLowerCase()) ||
          (`${item.first_name} ${item.surname}`).toLowerCase().includes(this.clientAssociationSearch.toLowerCase())
      }), 'client_id')

      return differenceWith(filteredBySearch, this.selectedProfileClientAssociations, (a, b) => isEqual(a.client_id, b.client_id))
    },

    filteredMortgageDealAssociations () {
      const filteredBySearch = orderBy(this.lists[MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL].filter(item => {
        return item.mortgage_deal_id.toString().includes(this.mortgageDealAssociationSearch.toLowerCase()) ||
          item.loan_code.toLowerCase().includes(this.mortgageDealAssociationSearch.toLowerCase())
      }), 'mortgage_deal_id')

      return differenceWith(filteredBySearch, this.selectedProfileMortgageDealAssociations, (a, b) => isEqual(a.mortgage_deal_id, b.mortgage_deal_id))
    },

    selectedProfileMortgageDealAssociations () {
      return this.agent[DATA][AGENT_TYPE.AGENT_PROFILE][this.selectedProfileIndex][AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN]
    },
    selectedProfileClientAssociations () {
      return this.agent[DATA][AGENT_TYPE.AGENT_PROFILE][this.selectedProfileIndex][AGENT_TYPE.AGENT_CLIENT_ASSN]
    },

    agentFieldGroups () {
      let fieldGroups = cloneDeep(this.agent.getFieldGroups(AGENT_TYPE.AGENT))
      fieldGroups[0].shift()
      return fieldGroups
    },

    isAgentPanelTextDisplayed () {
      return !this.panel.includes(0)
    },
    isAgentProfilePanelTextDisplayed () {
      return !this.panel.includes(1)
    },
    isAgentAliasPanelTextDisplayed () {
      return !this.panel.includes(2)
    },
    isAgentBusinessAddressPanelTextDisplayed () {
      return !this.panel.includes(3)
    },
    isAgentSocialMediaPanelTextDisplayed () {
      return !this.panel.includes(4)
    },
    isAgentTeamAssnPanelTextDisplayed () {
      return !this.panel.includes(5)
    },
    isClientAssociationPanelTextDisplayed () {
      return !this.panel.includes(6)
    },    
    isMortgageDealAssociationPanelTextDisplayed () {
      return !this.panel.includes(7)
    },

    mortgageDealAssociationSummaryText () {
      this.agent[DATA][AGENT_TYPE.AGENT_PROFILE]
      return this.agent.getSummaryText(AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN)
    },
    clientAssociationSummaryText () {
      this.agent[DATA][AGENT_TYPE.AGENT_PROFILE]
      return this.agent.getSummaryText(AGENT_TYPE.AGENT_CLIENT_ASSN)
    },

    isAgentSaveDisabled () {
      this.agent[ACTIONS.EDIT][AGENT_TYPE.AGENT] // This is here to force a recalculation on edit.

      return this.saving[AGENT_TYPE.AGENT] ||
        !this.validated[AGENT_TYPE.AGENT] || 
        !this.agent.hasDataChanged(AGENT_TYPE.AGENT)
    },
    isAgentProfileSaveDisabled () {
      this.agent[ACTIONS.EDIT][AGENT_TYPE.AGENT_PROFILE] // This is here to force a recalculation on edit.

      return this.saving[AGENT_TYPE.AGENT_PROFILE] ||
        !this.validated[AGENT_TYPE.AGENT_PROFILE] ||
        !this.agent.hasDataChanged(AGENT_TYPE.AGENT_PROFILE)
    },
    isAgentAliasSaveDisabled () {
      this.agent[ACTIONS.EDIT][AGENT_TYPE.AGENT_ALIAS] // This is here to force a recalculation on edit.

      return this.saving[AGENT_TYPE.AGENT_ALIAS] ||
        !this.validated[AGENT_TYPE.AGENT_ALIAS] ||
        !this.agent.hasDataChanged(AGENT_TYPE.AGENT_ALIAS)
    },
    isAgentBusinessAddressSaveDisabled () {
      this.agent[ACTIONS.EDIT][AGENT_TYPE.AGENT_BUSINESS_ADDRESS] // This is here to force a recalculation on edit.

      return this.saving[AGENT_TYPE.AGENT_BUSINESS_ADDRESS] ||
        !this.validated[AGENT_TYPE.AGENT_BUSINESS_ADDRESS] ||
        !this.agent.hasDataChanged(AGENT_TYPE.AGENT_BUSINESS_ADDRESS)
    },
    isAgentSocialMediaSaveDisabled () {
      this.agent[ACTIONS.EDIT][AGENT_TYPE.AGENT_SOCIAL_MEDIA] // This is here to force a recalculation on edit.

      return this.saving[AGENT_TYPE.AGENT_SOCIAL_MEDIA] ||
        !this.validated[AGENT_TYPE.AGENT_SOCIAL_MEDIA] ||
        !this.agent.hasDataChanged(AGENT_TYPE.AGENT_SOCIAL_MEDIA)
    },
    isAgentTeamAssnSaveDisabled () {
      this.agent[ACTIONS.EDIT][AGENT_TYPE.AGENT_TEAM_ASSN] // This is here to force a recalculation on edit.

      return this.saving[AGENT_TYPE.AGENT_TEAM_ASSN] ||
        !this.validated[AGENT_TYPE.AGENT_TEAM_ASSN] ||
        !this.agent.hasDataChanged(AGENT_TYPE.AGENT_TEAM_ASSN)
    },

    agentProfileLabel () {
      return this.agent.isEditing(AGENT_TYPE.AGENT_PROFILE) ? 'Read-only during edit...' : 'Select an agent profile...'
    },
    remainingAgentProfiles () {
      return differenceWith(
        this.lists[VALID_TABLES.AGENT_PROFILE],
        this.agent[DATA][AGENT_TYPE.AGENT_PROFILE],
        (a, b) => isEqual(a.profile_id, b.profile_id)
      )
    },
    remainingSocialMedia () {
      return differenceWith(
        this.lists[VALID_TABLES.SOCIAL_MEDIA],
        this.agent[DATA][AGENT_TYPE.AGENT_SOCIAL_MEDIA],
        (a, b) => isEqual(a.social_media, b.social_media)
      )
    },
    selectedProfileIndex() {
      if (this.agent[DATA][AGENT_TYPE.AGENT_PROFILE].length === 0) {
        return 0
      }
      for (let i = 0; i < this.agent[DATA][AGENT_TYPE.AGENT_PROFILE].length; i++) {
        if (this.agent[DATA][AGENT_TYPE.AGENT_PROFILE][i].profile_id === this.selectedProfile) {
          return i
        }
      }
      return 0
    },

    /**
     * Metadata required by API calls.
     */
    apiCallMetadata () {
      return { operator_id: this.user.id, dlc: now(), agent_id: this.agent_id }
    },
  },
  methods: {
    ...mapActions({
      fetchBasicMortgageDealList: 'mortgageDeal/fetchBasicList',
      fetchAgent: 'agent/fetchAgent',
      fetchClients: 'client/fetchClients',
      fetchList: 'agent/fetchList',

      fetchValidList: 'valid/fetchList',

      createData: 'agent/createData',
      deleteData: 'agent/deleteData',
      updateData: 'agent/updateData',

      enqueueMessage: 'message/enqueueMessage',
      successMessage: 'message/successMessage',
      errorMessage: 'message/errorMessage',
      initValidStore: 'valid/initStore'
    }),
    ...mapMutations({
      cacheAgent: 'agent/cacheAgent'
    }),
    /**
     * Send an API request to create data from the data-type and (optional) iterable item.
     * @param {string} type Agent.TYPE
     * @param {*} item Item from an iterable Agent.TYPE
     */
    createAgentData(type, item) {
      this.saving[type] = true

      let payload = {
        ...(item) ? item : this.agent.getData(type),
        ...this.apiCallMetadata,
      }

      // For the client/mortgage deal associations, add the agent profile id to the payload.
      if (type === AGENT_TYPE.AGENT_CLIENT_ASSN || type === AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN) {
        payload.id = this.agent.getAllProfiles()[this.selectedProfileIndex].id
        payload.profile_id = payload.id
      }

      this.createData({ type, payload })
        .then(data => {
          if (isTypeIterable(type)) {
            this.agent.appendData(data, type, this.selectedProfileIndex)
          }
          else {
            this.agent.setData(data, type)
          }
          this.agent.disableEdit(type)
          this.cacheAgent({ agent: this.agent, agent_id: payload.agent_id })
          this.dialogClose(DIALOGS.CRUD)
          this.enqueueMessage({ action: ACTIONS.CREATE, type: MESSAGE_TYPE.SUCCESS, data: payload, dataType: type })

          // Refresh agent to get new agent_alias data.
          if (type = this.AGENT_PROFILE) {
            this.refreshAgent()
          }
        })
        .catch(error => {
          this.enqueueMessage({ action: ACTIONS.CREATE, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
        })
        .finally(() => {
          this.saving[type] = false
        })
    },
    /**
     * Send an API request to delete data from the data-type and (optional) iterable item.
     * @param {string} type Agent.TYPE
     * @param {*} item Item from an iterable Agent.TYPE
     */
    async deleteAgentData (type, item) {
      this.deleting[type] = true
      this.dialogClose(DIALOGS.CONFIRMATION)

      const payload = {
        ...(item) ? item : this.agent.getData(type),
      }

      let config = {
        type,
        agent_id: payload.agent_id,
        id: payload.id
      }

      if (type === AGENT_TYPE.AGENT_CLIENT_ASSN || type === AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN) {
        config.assn_id = config.id
        config.id = item.profile_id
      }

      this.deleteData(config)
        .then(() => {
          if (isTypeIterable(type)) {
            this.agent.deleteData(item, type, this.selectedProfileIndex)
          }
          else {
            this.agent.setToDefaultData(type)
          }

          this.agent.disableEdit(type)
          this.cacheAgent({ agent: this.agent, agent_id: payload.agent_id })
          this.enqueueMessage({ action: ACTIONS.DELETE, type: MESSAGE_TYPE.SUCCESS, data: payload, dataType: type })

          // Refresh agent to get new agent_alias data.
          if (type = this.AGENT_PROFILE) {
            this.refreshAgent()
          }
        })
        .catch(error => {
          this.enqueueMessage({ action: ACTIONS.DELETE, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
        })
        .finally(() => {
          this.deleting[type] = false

          if (type === AGENT_TYPE.AGENT_PROFILE) {
            this.selectedProfile = this.agent[DATA][AGENT_TYPE.AGENT_PROFILE][0].profile_id
          }
        })
    },
    /**
     * Close and reset a dialog window.
     * @param {string} type Dialog window type.
     */
    dialogClose (type) {
      this.dialog[type] = { show: false, config: {}, payload: {}, }
    },
    /**
     * Initialize the agent DTO for this page.
     */
    async initAgent () {
      try {
        const results = await Promise.all([
          this.initValidStore(),
          this.fetchAgent({ agent_id: this.agent_id }),
          this.fetchBasicMortgageDealList({ force: false }),
          this.fetchClients({ force: false })
        ])

        // Setup the agent and add any valid table dependencies to its metadata.
        this.agent = results[1]
        this.agentExists = !isNil(this.agent)
        this.selectedProfile = 'A'

        this.lists[MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL] = results[2]
        this.lists[CLIENT_TYPE.CLIENT] = results[3]
      }
      catch (error) {
        this.agentExists = false
        this.errorMessage(`Failed to initialize agent.`)
      }
    },
    /**
     * Initialize the constants used in this view.
     */
    initTemplateConstants () {
      this.AGENT = AGENT_TYPE.AGENT
      this.AGENT_ALIAS = AGENT_TYPE.AGENT_ALIAS
      this.AGENT_BUSINESS_ADDRESS = AGENT_TYPE.AGENT_BUSINESS_ADDRESS
      this.AGENT_CLIENT_ASSN = AGENT_TYPE.AGENT_CLIENT_ASSN
      this.AGENT_MORTGAGE_DEAL_ASSN = AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN
      this.AGENT_PROFILE = AGENT_TYPE.AGENT_PROFILE
      this.AGENT_SOCIAL_MEDIA = AGENT_TYPE.AGENT_SOCIAL_MEDIA
      this.AGENT_TEAM = AGENT_TYPE.AGENT_TEAM_ASSN

      this.SELECT = FIELD_TYPES.SELECT
      this.TEXT = FIELD_TYPES.TEXT

      this.AGENTS_ROUTE = ROUTES.AGENTS

      this.AGENT_VUE = VUES.AGENT

      this.AGENT_VUE_AGENT_EXPAND = EXPANDS.AGENT_VUE_AGENT
      this.AGENT_VUE_AGENT_ALIAS_EXPAND = EXPANDS.AGENT_VUE_AGENT_ALIAS
      this.AGENT_VUE_AGENT_PROFILE_EXPAND = EXPANDS.AGENT_VUE_AGENT_PROFILE
      this.AGENT_VUE_BUSINESS_ADDRESS_EXPAND = EXPANDS.AGENT_VUE_BUSINESS_ADDRESS
      this.AGENT_VUE_SOCIAL_MEDIA_EXPAND = EXPANDS.AGENT_VUE_SOCIAL_MEDIA
      this.AGENT_VUE_TEAM_ASSN_EXPAND = EXPANDS.AGENT_VUE_TEAM_ASSN

      this.AGENT_BOX_ICON = ICONS.AGENT_BOX
      this.AGENT_ICON = ICONS.AGENT
      this.DELETE_ICON = ICONS.DELETE
      this.INFO_ICON = ICONS.INFO

      this.CONFIRMATION = DIALOGS.CONFIRMATION
      this.CRUD = DIALOGS.CRUD
      
      this.INFO = COLOR.INFO

      this.DATA = DATA
      this.DELETE = ACTIONS.DELETE
    },
    /**
     * Initialize any functions that are used within the template.
     */
    initTemplateFunctions() {
      this.isEmpty = isEmpty
    },
    /**
     * Initialize the various lists used by the Agent.
     */
    async initValidLists() {
      this.lists[VALID_TABLES.AGENT_PROFILE] = await this.fetchValidList({ type: VALID_TABLES.AGENT_PROFILE })
      this.lists[VALID_TABLES.AGENT_TEAM] = await this.fetchValidList({ type: VALID_TABLES.AGENT_TEAM })
      this.lists[VALID_TABLES.PROVINCE] = await this.fetchValidList({ type: VALID_TABLES.PROVINCE })
      this.lists[VALID_TABLES.SOCIAL_MEDIA] = await this.fetchValidList({ type: VALID_TABLES.SOCIAL_MEDIA })
    },
    /**
     * Flag that indicates when an agent data-type has its "Add" button disabled.
     * @param {string} type Agent.TYPE
     * @return True when adding for this data-type is invalid, false otherwise.
     */
    isAddDisabled (type) {
      switch (type) {
        case AGENT_TYPE.AGENT_PROFILE:
          return !this.lists[VALID_TABLES.AGENT_PROFILE] || this.agent.getAllProfiles().length === this.lists[VALID_TABLES.AGENT_PROFILE].length

        case AGENT_TYPE.AGENT_SOCIAL_MEDIA:
          return !this.lists[VALID_TABLES.SOCIAL_MEDIA] || this.agent.getAllSocialMedia().length === this.lists[VALID_TABLES.SOCIAL_MEDIA].length
      }
    },
    /**
     * Flag that indicates when an agent data-type has its "Add" button disabled.
     * @param {string} type Agent.TYPE
     * @return True when adding for this data-type is invalid, false otherwise.
     */
    isDeleteDisabled (type) {
      switch (type) {
        case AGENT_TYPE.AGENT_PROFILE:
          return this.saving[type] || this.selectedProfile === 'A'
      }
    },
    /**
     * Opens the confirmation dialog box and configures it based on the action and data-type being
     * confirmed.
     * @param {string} action Action to confirm.
     * @param {string} type Agent.TYPE
     * @param {*} payload Data acted upon.
     */
    openConfirmationDialog (action, type, payload) {
      this.dialog[DIALOGS.CONFIRMATION].payload = payload
      
      let message = ''
      let target = ''

      switch (type) {
        case AGENT_TYPE.AGENT_PROFILE:
          message = 'Delete this agent profile?'
          target = `Profile ${payload.profile_id}`
          break

        case AGENT_TYPE.AGENT_SOCIAL_MEDIA:
          message = 'Delete this agent\'s social media?'
          target = `${payload.social_media} (${payload.url})`
          break

        case AGENT_TYPE.AGENT_TEAM_ASSN:
          message = 'Delete this agent\'s team association?'
          target = `Team ${payload.team_name}`
          break

        case AGENT_TYPE.AGENT_CLIENT_ASSN:
          message = 'Delete this agent-client association?'
          target = `Client ${payload.client_id}`
          break

        case AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN:
          message = 'Delete this agent-mortgage deal association?'
          target = `Mortgage Deal ${payload.mortgage_deal_id}`
          break

        default:
          this.errorMessage(`Unsupported type: ${type}`)
      }

      switch (action) {
        case ACTIONS.DELETE:
          this.dialog[DIALOGS.CONFIRMATION].config = { action, type, title: 'Delete Confirmation', message, target }
          break

        default:
          this.errorMessage(`Unsupported action: ${action}`)
      }

      this.dialog[DIALOGS.CONFIRMATION].show = true
    },
    /**
     * Initializes and opens the dialog window for this data-type with default values set for the fields.
     * @param {string} type Agent.TYPE
     */
    openCrudDialog (type) {
      this.dialog[DIALOGS.CRUD].config = {
        type,
        fields: {
          fieldGroups: cloneDeep(this.agent.getFieldGroups(type)),
        },
      }
      this.dialog[DIALOGS.CRUD].payload = cloneDeep(this.agent.getDefaultItem(type))

      // Set any selects to its first item.
      forEach(this.dialog[DIALOGS.CRUD].config.fields.fieldGroups, fieldGroup => {
        forEach(fieldGroup, field => {
          if (field.fieldType === FIELD_TYPES.SELECT) {
            if (field.property === PROPERTY.PLURAL) {
              this.dialog[DIALOGS.CRUD].payload[field.property] = 'N'
            }
            if (field.property === PROPERTY.TEAM_NAME) {
              this.dialog[DIALOGS.CRUD].payload[field.property] = field.items[0].team_name
            }
          }
        })
      })

      switch (type) {
        case AGENT_TYPE.AGENT_PROFILE:
          this.dialog[DIALOGS.CRUD].config.title = 'Add Profile'

          let profileSoloField = this.agent.getSoloFields(AGENT_TYPE.AGENT_PROFILE)[PROPERTY.PROFILE_ID]
          profileSoloField.items = this.remainingAgentProfiles

          // Add a stand-alone field for the profile ID and set its default value.
          this.dialog[DIALOGS.CRUD].config.fields.fieldGroups[0].unshift(profileSoloField)
          this.dialog[DIALOGS.CRUD].payload.profile_id = this.remainingAgentProfiles[0].profile_id
          break
        case AGENT_TYPE.AGENT_SOCIAL_MEDIA:
          this.dialog[DIALOGS.CRUD].config.title = 'Add Social Media'
          
          let socialMediaSoloField = this.agent.getSoloFields(AGENT_TYPE.AGENT_SOCIAL_MEDIA)[PROPERTY.SOCIAL_MEDIA]
          socialMediaSoloField.items = this.remainingSocialMedia

          // Add a stand-alone field for social media and set its default value.
          this.dialog[DIALOGS.CRUD].config.fields.fieldGroups[0].unshift(socialMediaSoloField)
          this.dialog[DIALOGS.CRUD].payload.social_media = this.remainingSocialMedia[0].social_media
          break
      }

      delete this.dialog[DIALOGS.CRUD].payload['default'];

      this.dialog[DIALOGS.CRUD].show = true
    },
    async refreshAgent () {
      this.isInitializing = true
      try {
        this.agent = await this.fetchAgent({ agent_id: this.agent_id, force: true })
        this.agentExists = !isNil(this.agent)
      }
      catch (error) {
        this.agentExists = false
        this.errorMessage(`Failed to fetch agent.`)
      }
      this.isInitializing = false
    },
    /**
     * Remove any unmodified data from the payload.
     * @param {string} type Agent.TYPE.
     * @param {array} payload Array of payloads.
     */
    removeUnmodified(type, payload) {
      if (type === AGENT_TYPE.AGENT_SOCIAL_MEDIA) {
        return differenceWith(payload, this.agent[SAVED_DATA][type], isEqual)
      }
      return payload
    },
    /**
     * Send an update API request for the data-type.
     * @param {string} type Agent.TYPE
     */
    async updateAgentData (type) {
      this.saving[type] = true

      let data = this.agent.getData(type)
      let payload = null

      if (isTypeIterable(type)) {
        switch(type) {
          case AGENT_TYPE.AGENT_PROFILE:
            data = filter(data, datum => datum.profile_id === this.selectedProfile)[0]
            payload = {
              ...data,
              ...this.apiCallMetadata
            }
            break
          
          case AGENT_TYPE.AGENT_ALIAS:
            data = filter(data, datum => datum.profile_id === this.selectedProfile)[0]
            payload = {
              ...data,
              ...this.apiCallMetadata
            }
            break

          case AGENT_TYPE.AGENT_SOCIAL_MEDIA:
            data = this.removeUnmodified(type, data)
            data = forEach(data, datum => {
              return {
                ...datum,
                ...this.apiCallMetadata
              }
            })
            if (data.length === 1) {
              data = data[0]
            }
            payload = { data }
            break
        }
      }
      else {
        payload = {
          ...data,
          ...this.apiCallMetadata
        }
      }

      let updatedData = null

      // Send several individual update calls.
      if (Array.isArray(payload.data)) {
        let updatePromises = []
        forEach(payload.data, (datum) => {
          updatePromises.push(
            this.updateData({ type, payload: datum }),
          )
        })

        try {
          updatedData = await Promise.all(updatePromises)
        }
        catch (error) {
          this.enqueueMessage({ action: ACTIONS.UPDATE, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
        }
      }
      // Send a single update call.
      else {
        try {
          updatedData = await this.updateData({ type, payload })
        }
        catch (error) {
          this.enqueueMessage({ action: ACTIONS.UPDATE, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
        }
      }

      // Save the data. If it's an iterable type, fetch a new list first.
      if (isTypeIterable(type)) {
        try {
          updatedData = await this.fetchList({ type, agent_id: this.agent_id, })

          if (type === AGENT_TYPE.AGENT_PROFILE) {
            let associationPromises = []
            forEach(updatedData, (profile, i) => {
              associationPromises.push(
                this.fetchList({ type: AGENT_TYPE.AGENT_CLIENT_ASSN, agent_id: profile.agent_id, id: profile.id, }),
                this.fetchList({ type: AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN, agent_id: profile.agent_id, id: profile.id, }),
              )
            })

            try {
              const results = await Promise.all(associationPromises)

              forEach(updatedData, (profile, i) => {
                updatedData[i][AGENT_TYPE.AGENT_CLIENT_ASSN] = results[i * 2]
                updatedData[i][AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN] = results[(i * 2) + 1]
              })
            }
            catch (error) {
              this.errorMessage(`Error during association fetches for updated agent ${this.agent_id}: ${error}`)
            }
          }
        }
        catch (error) {
          this.errorMessage(`Error fetching replacement data for the '${type}' data iterable: ${error}`)
        }
      }

      // TODO: Handle enqueuing messages from single and multi payload objects.
      this.successMessage(`${startCase(type)} update successful.`)
      this.agent.setData(updatedData, type)
      this.agent.disableEdit(type)
      this.cacheAgent({ agent: this.client, agent_id: payload.agent_id })

      this.saving[type] = false
    },
  },
  async created() {
    this.isInitializing = true
    this.agent_id = this.$route.params.id
    
    this.panelCount = 8
    this.panelsExpanded = range(this.panelCount)
    this.panel = []

    this.initTemplateConstants()
    this.initTemplateFunctions()
    await this.initAgent()
    this.initValidLists()

    this.isInitializing = false
  },
}
</script>

<style scoped>
button {
  outline: none;
}
</style>
